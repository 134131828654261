import { lazy } from 'react';
import { Navigate, useLocation, useRoutes } from 'react-router-dom';
import { isAuthenticated } from 'utils/authentication';
import Loadable from 'utils/Loadable';
import CustomerRoutes from './CustomerRoutes';
import EventRoutes from './EventRoutes';
import { path } from './path';

const Layout = Loadable(lazy(() => import('containers/Layout')));
const AuthPage = Loadable(lazy(() => import('containers/Auth')));
const NotFoundPage = Loadable(lazy(() => import('components/NotFound')));

const AdminPage = Loadable(lazy(() => import('containers/Admin')));
const GroupPermissionPage = Loadable(lazy(() => import('containers/GroupPermission')));
const PermissionPage = Loadable(lazy(() => import('containers/Permission')));
const WithdrawalPage = Loadable(lazy(() => import('containers/Withdrawal')));
const DashboardPage = Loadable(lazy(() => import('containers/Dashboard')));
const BannerPage = Loadable(lazy(() => import('containers/Banner')));
const TicketHTPage = Loadable(lazy(() => import('containers/TicketHT')));
const TicketMLPage = Loadable(lazy(() => import('containers/TicketML')));
const TicketNewPage = Loadable(lazy(() => import('containers/TicketNew')));
const TicketLasaPage = Loadable(lazy(() => import('containers/TicketLasa')));
const LeaguePage = Loadable(lazy(() => import('containers/League')));
const TeamPage = Loadable(lazy(() => import('containers/Team')));
const DialPage = Loadable(lazy(() => import('containers/Dial')));
const DialNewPage = Loadable(lazy(() => import('containers/DialNew')));
const DialLasaPage = Loadable(lazy(() => import('containers/DialLasa')));
const DialMLPage = Loadable(lazy(() => import('containers/DialML')));

const Router = () => {
  const location = useLocation();

  return useRoutes([
    {
      path: path.root,
      element: isAuthenticated() ? <Layout /> : <Navigate to={path.login} state={{ from: location }} />,
      children: [
        {
          index: true,
          element: <TicketNewPage />,
        },
        {
          path: path.ticket_ht,
          element: <TicketHTPage />,
        },
        {
          path: path.ticket_ml,
          element: <TicketMLPage />,
        },
        {
          path: path.ticket_new,
          element: <TicketNewPage />,
        },
        {
          path: path.ticket_lasa,
          element: <TicketLasaPage />,
        },
        {
          path: path.dial,
          element: <DialPage />,
        },
        {
          path: path.dial_new,
          element: <DialNewPage />,
        },
        {
          path: path.dial_lasa,
          element: <DialLasaPage />,
        },
        {
          path: path.dial_ml,
          element: <DialMLPage />,
        },
        ...EventRoutes,
        ...CustomerRoutes,
        {
          path: path.admin,
          element: <AdminPage />,
        },
        {
          path: path.group_permission,
          element: <GroupPermissionPage />,
        },
        {
          path: path.permission,
          element: <PermissionPage />,
        },
        {
          path: path.withdrawal,
          element: <WithdrawalPage />,
        },
        {
          path: path.dashboard,
          element: <DashboardPage />,
        },
        {
          path: path.banner,
          element: <BannerPage />,
        },
        {
          path: path.league,
          element: <LeaguePage />,
        },
        {
          path: path.team,
          element: <TeamPage />,
        },
      ],
    },
    {
      path: path.notFound,
      element: <NotFoundPage />,
    },
    {
      path: path.login,
      element: isAuthenticated() ? <Navigate to={path.root} state={{ from: location }} /> : <AuthPage />,
    },
  ]);
};

export default Router;
